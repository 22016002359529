import {
  useEffect,
} from 'react'
import {
  animated, useSpring,
} from '@react-spring/web'
import {
  useParams,
} from 'react-router-dom'

import {
  useFetchUserQuery,
} from '@root/services/user'
import {
  EstimationStatus,
} from '@root/domain/Estimation'
import CallbackBanner from '@components/offers/CallbackBanner'
import {
  useGetInsurersQuery,
} from '@root/services/insurers'
import {
  settings,
} from '@root/settings'
import useGetOffersByEstimationId from '@hooks/offers/useGetOffersByEstimationId'

import {
  addMatchRateBannerWarning,
} from './OfferListDecorator'

const Offers = () => {
  const params = useParams()
  const estimationId = params.estimationId ?? ''

  useGetInsurersQuery()

  const {
    data,
    refetch,
    error,
    isSuccess,
  } = useGetOffersByEstimationId(estimationId)

  const fetchUser = useFetchUserQuery(data?.estimation?.userId ?? '', {
    skip: !isSuccess,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (data?.estimation?.status === EstimationStatus.COMPLETE || error) {
        clearInterval(interval)
        return
      }

      refetch()
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [data, refetch, error])

  const [offerListStyle] = useSpring({
    from: {
      transform: 10,
      opacity: 0,
    },
    to: {
      transform: fetchUser.isSuccess && isSuccess ? 0 : 10,
      opacity: fetchUser.isSuccess && isSuccess ? 1 : 0,
    },
    leave: [
      {
        transform: 'none',
      }
    ],
  }, [fetchUser, isSuccess])

  const items = addMatchRateBannerWarning(data?.items, settings.offerSortStategy)

  const withBanner = items?.length
    ? [...items.slice(0, 3),
      <CallbackBanner
        key='callback-banner'
        title='Vous n’êtes pas sûr(e) ?'
      />, ...items.slice(3, items.length)]
    : [
      <CallbackBanner key='callback-banner'
        title='Besoin d’aide ?'
      />]

  return (
    <div className='flex h-full flex-col gap-8 md:gap-12'>
      <animated.div className='flex w-full flex-col gap-4 md:gap-12' role="list"
        style={{
          ...offerListStyle,
          transform: offerListStyle.transform.to(t => {
            return (t ? `translateY(${t}%)` : 'none')
          }),
        }}
      >
        {...withBanner}
      </animated.div>
    </div>
  )
}

export default Offers
