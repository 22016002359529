import {
  FC,
} from 'react'
import {
  Button,
  ButtonSize,
  ButtonType,
  Header as SelectraHeader,
} from '@selectra-it/selectra-ui'
import {
  Link,
} from 'react-router-dom'
import {
  useDispatch,
} from 'react-redux'

import Stepper from '@components/wizard/Stepper'
import SelectraLogoMobile from '@assets/selectra-logo-mobile.svg?react'
import SelectraLogoDesktop from '@assets/selectra-logo-desktop.svg?react'
import Icon from '@components/Icon'
import useIsOfferPage from '@hooks/useIsOfferPage'
import {
  HELP_MODAL,
  OFFER_FILTER_MODAL, toggleModal,
} from '@root/services/modalSlice'

import HelpModal from './HelpModal'
import CoverageLevelFilterModal from './CoverageLevelFilterModal'

const Header: FC = () => {
  const dispatch = useDispatch()
  const {
    isOfferPage, isOfferPageLoaded,
  } = useIsOfferPage()

  return (
    <SelectraHeader
      leftSlot={(
        <Link to={'/'}>
          <SelectraLogoMobile className="block md:hidden" />
          <SelectraLogoDesktop className="hidden md:block" />
        </Link>
      )}
      variant={isOfferPage ? 'fluid' : 'default'}
      rightSlot={(
        <>
          <HelpModal />
          {isOfferPage && isOfferPageLoaded && (
            <CoverageLevelFilterModal />
          )}
          <div className='flex items-center gap-4'>
            <a href="#" className='text-sm font-bold leading-tight text-primary-400 underline'
              onClick={() => {
                dispatch(toggleModal(HELP_MODAL))
              }}
            >
              Aide
            </a>
            {isOfferPage && (
              <div className='block lg:hidden'>
                <Button variant={ButtonType.LIGHT} size={ButtonSize.SMALL}
                  onClick={() => {
                    dispatch(toggleModal(OFFER_FILTER_MODAL))
                  }}
                  label={'Modifier mes niveaux'}
                  iconLeft={() => <Icon name='edit' size={16} />}
                  hasBorderColor={true}
                />

              </div>
            )}
          </div>
        </>
      )}
      centerSlot={!isOfferPage && (
        <div className='container mx-auto hidden items-start md:flex'>
          <Stepper />
        </div>
      )}
    />
  )
}

export default Header
