import {
  Button, ButtonSize, ButtonType,
} from '@selectra-it/selectra-ui'
import {
  FC, useState,
} from 'react'

import ArrowExpandLess from '@assets/icons/arrow-expand-less.svg?react'
import {
  Offer,
} from '@root/domain/Offer'
import ArrowExpandMore from '@assets/icons/arrow-expand-more.svg?react'
import Flag from '@components/ui/Flag'
import {
  tagColors,
  useGetEstimationTags,
} from '@hooks/offers/useGetEstimationTags'
import TagContainer from '@components/ui/TagContainer'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import Price from '@components/offers/Price'
import {
  insurerLogos,
} from '@root/domain/Insurer'
import Icon from '@components/Icon'

import Level from './Level'
import ExpandedOffer from './ExpandedOffer'
import SubscriptionButton from './Subscription/SubscriptionButton'
import AlternativeMedicine from './AlternativeMedicine'
import OfferProvider from './OfferProvider'

interface OfferItemProps {
  offer: Offer,
}

const OfferItem: FC<OfferItemProps> = ({
  offer,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const {
    matchRate, monthlyPrice, title, insurerId, coverage, providerId,
  } = offer

  const {
    tag,
  } = useGetEstimationTags(offer.estimationId, offer.id)
  const insurer = useAppSelector(state => selectInsurerById(state, insurerId))

  const handleOnMoreClick = () => {
    setIsExpanded(!isExpanded)
  }

  const borderColor = tag ? tagColors[tag].border : ''

  if (!insurer) {
    return null
  }

  const Logo = insurerLogos[insurer.id]

  return (
    <div className="flex w-full flex-col items-start p-0 last:mb-6 md:last:mb-24">
      { tag && <TagContainer tag={tag} />}
      <div className={`flex w-full flex-col gap-6 whitespace-nowrap border-y border-neutral-100 bg-white p-4 pb-6 md:rounded-2xl md:border md:p-6 ${tag ? `md:border-2 ${borderColor}` : ''}`} >
        <div className='flex flex-col gap-2'>
          <div className='mb-6 md:hidden'>
            {tag && (
              <Flag
                tag={tag}
              />)}
          </div>
          <div className=' flex w-full flex-row items-center justify-between'>
            <div className='flex flex-col items-start justify-start gap-1'>
              {typeof Logo === 'string'
                ? <img src={Logo} className='h-10'/>
                : <Logo height={40} /> }
              <p className='mt-1 font-semibold text-neutral-500 md:text-lg'>{title}</p>
              {providerId && <OfferProvider providerId={providerId} />}
            </div>

            <Price price={monthlyPrice} />
          </div>
          <div className='flex items-center gap-2'>
            <Icon name='extension' size={24}
              className='fill-success-medium'
            />
            <p className='text-sm text-neutral-350 md:text-base'>

            Répond à <strong>{matchRate} %</strong> de vos besoins</p>
          </div>
        </div>

        <div>
          <h3 className='mb-2 font-semibold text-neutral-500 md:text-lg'>Niveau de remboursement</h3>
          <ul className='mb-2 flex flex-col gap-2'>
            {['consultation', 'hospitalization', 'dental', 'optical'].map(key => {
              return coverage[key] && (
                <Level
                  key={key}
                  level={coverage[key]}
                  label={key}
                  isIncluded={false}
                />
              )
            })}
            <AlternativeMedicine alternativeMedicine={offer.alternativeMedicine} />
          </ul>

          <ExpandedOffer isExpanded={isExpanded} offer={offer} />
        </div>

        <div className='flex gap-4 first:whitespace-nowrap'>
          <div className='shrink grow'>
            <Button
              label={isExpanded ? 'Moins d\'info' : 'Plus d\'info'}
              type='submit'
              variant={ButtonType.LIGHT}
              size={ButtonSize.MEDIUM}
              iconRight={isExpanded ? ArrowExpandLess : ArrowExpandMore}
              pill
              onClick={handleOnMoreClick}
              hasBorderColor={true}
            />
          </div>
          <div className='shrink grow'>
            <SubscriptionButton offer={offer} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfferItem
