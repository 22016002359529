import {
  Outlet,
  useSearchParams,
} from 'react-router-dom'
import {
  Subheading, SubheadingType,
} from '@selectra-it/selectra-ui'

import SubscriptionModal from '@components/ui/SubscriptionModal/SubscriptionModal'

import Offers from './Offers'
import LoadingPage from './LoadingPage/LoadingPage'
import OfferFilters from './OfferFilters'

const OffersPage = () => {
  const [searchParams] = useSearchParams()

  return (
    <div id="offers-page">
      <div className='py-12' id="offers-page-header">
        <div className='container-fluid mx-auto flex flex-col gap-4 px-4 md:px-0'>
          <Subheading type={SubheadingType.PRIMARY}>Voici la liste des offres les plus adaptées à votre profil 👍</Subheading>
        </div>
      </div>

      <div className='container-fluid mx-auto pb-12 md:py-12'>
        <div className='grid w-full grid-cols-8 gap-[30px]'>
          <div className='relative hidden lg:col-span-3 lg:block'>
            <div className='sticky top-20'>
              <OfferFilters />
            </div>
          </div>
          <div className='col-span-8 lg:col-span-5'>
            <Offers />
          </div>
        </div>
      </div>
      {(searchParams.get('preload')) && <LoadingPage />}
      <SubscriptionModal />
      <Outlet />
    </div>
  )
}

export default OffersPage
