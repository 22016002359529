import {
  FC,
} from 'react'
import clsx from 'clsx'

import {
  Coverage,
} from '@root/domain/Offer'

const CoverageLevelNumber: Record<Coverage, number> = {
  [Coverage.MIN]: 1,
  [Coverage.LOW]: 2,
  [Coverage.MEDIUM]: 3,
  [Coverage.HIGH]: 4,
}

interface StepProps {
  level: Coverage;
}

const Step: FC<StepProps> = ({
  level,
}) => {
  const steps = Array.from({
    length: 4,
  })

  return (
    <div className="flex w-full flex-1 space-x-1">
      {steps.map((_, index) => (
        <span
          key={index}
          className={clsx(
            'h-2 flex-1 rounded-sm first:rounded-l-md last:rounded-r-md',
            {
              'bg-neutral-100': CoverageLevelNumber[level] <= index,
              'bg-success-light': CoverageLevelNumber[level] > index,
            }
          )}
        />
      ))}
    </div>
  )
}

export default Step
