import {
  useDispatch,
} from 'react-redux'
import {
  useEffect,
} from 'react'

import {
  selectOfferFilters,
  setFilters,
} from '@root/services/filterSlice'
import {
  useGetOffersByEstimationIdQuery,
} from '@root/services/offers'
import {
  useAppSelector,
} from '@root/store/Reducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useGetOffersByEstimationId = (estimationId: string, params?: any) => {
  const filters = useAppSelector(selectOfferFilters)
  const dispatch = useDispatch()

  const result = useGetOffersByEstimationIdQuery({
    estimationId,
    ...filters,
    alternativeMedicine: Boolean(filters.alternativeMedicine),
  }, {
    skip: !estimationId,
    ...params,
  })

  // Set filters from estimation if not already set
  useEffect(() => {
    if (!filters.dentalLevel && result.data) {
      dispatch(
        setFilters({
          dentalLevel: result.data?.estimation?.dental,
          hospitalLevel: result.data?.estimation?.hospitalization,
          opticalLevel: result.data?.estimation?.optical,
          regularHealthLevel: result.data?.estimation?.consultation,
        })
      )
    }
  }, [filters.dentalLevel, result.data, dispatch])

  return {
    ...result,
  }
}

export default useGetOffersByEstimationId
