import {
  FC,
  useRef,
} from 'react'
import {
  InputGroup, getErrorMessages, ErrorMessages,
  RadioStackedContainer,
  RadioStacked,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  SubmitHandler,
} from 'react-hook-form'
import {
  useDispatch,
} from 'react-redux'

import useEstimationWizardForm from '@hooks/useWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'
import useSubmitFormOnChange from '@hooks/useSubmitFormOnChange'
import useGetRadioStatus from '@hooks/useGetRadioStatus'
import CoverageLevelIndicator from '@components/ui/CoverageLevel'
import {
  setFormField,
} from '@services/formSlice'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  CoverageOptions as options,
} from '@root/domain/Offer'

const InsuranceLevel: FC = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const {
    t,
  } = useTranslation(['wizard'])

  const {
    form: {
      register,
      handleSubmit,
      watch,
      formState: {
        errors,
        isDirty,
        isSubmitting,
        isSubmitted,
      },
    },
    next, onBackHandler,
  } = useEstimationWizardForm({})

  const dispatch = useDispatch()

  const errorType = errors.insuranceLevel?.type

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errorType)
  const insuranceLevelStatus = useGetRadioStatus(errors.insuranceLevel?.type)

  useSubmitFormOnChange(isDirty, watch('insuranceLevel'), formRef)

  if (!options) return null

  const onSubmit: SubmitHandler<WizardFormData> = async data => {
    await dispatch(setFormField(data.insuranceLevel === ''
      ? data
      : {
        ...data,
        regularHealthLevel: data.insuranceLevel,
        opticalLevel: data.insuranceLevel,
        dentalLevel: data.insuranceLevel,
        hospitalLevel: data.insuranceLevel,
      }))

    next?.(data, {})
  }

  return (
    <form
      className='flex w-full grow flex-col justify-between space-y-6'
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
    >
      <InputGroup
        errors={inputErrors}
      >
        <RadioStackedContainer>
          {options.filter(option => option.id !== 'MEDIUM').map(({
            id,
            label,
            value,
          }) => (
            <RadioStacked
              key={id}
              id={id}
              item={{
                label,
                value,
                description: t(`wizard:wizard.steps.coverageLevel.${id}.insuranceLevel` as 'wizard:wizard.steps.coverageLevel.MIN.insuranceLevel'),
              }}
              status={insuranceLevelStatus}
              {...register('insuranceLevel', {
                required: true,
              })}
              dataCy={value}
              variant='free'
            >
              <div className='flex w-full items-center gap-4 self-stretch'>
                <CoverageLevelIndicator level={id} text={t(`wizard:wizard.steps.coverageLevel.${id}.insuranceLevelLabel`)} />
                <div className='flex w-full items-center gap-4 self-stretch'>
                  <div className="shrink grow basis-0" dangerouslySetInnerHTML={{
                    __html: t(`wizard:wizard.steps.coverageLevel.${id}.insuranceLevel` as 'wizard:wizard.steps.coverageLevel.MIN.insuranceLevel'),
                  }}
                  />
                </div>
              </div>
            </RadioStacked>
          ))}
          <RadioStacked
            id={'NONE'}
            item={{
              label: 'label',
              value: '',
              description: t('wizard:wizard.steps.coverageLevel.NONE.insuranceLevel'),
            }}
            status={insuranceLevelStatus}
            {...register('insuranceLevel', {
              required: true,
            })}
            dataCy={''}
            variant='free'
          >
            <div className='flex w-full items-center gap-4 self-stretch'>
              <CoverageLevelIndicator text="Adapté" />
              <div className='flex w-full items-center gap-4 self-stretch'>
                <div className="shrink grow basis-0" dangerouslySetInnerHTML={{
                  __html: t('wizard:wizard.steps.coverageLevel.NONE.insuranceLevel'),
                }}
                />
              </div>
            </div>
          </RadioStacked>
        </RadioStackedContainer>

      </InputGroup>

      <NavigationButtons onBackHandler={onBackHandler} disabled={isSubmitting || isSubmitted}/>

    </form>
  )
}

export default InsuranceLevel
