import {
  useTransition, animated,
} from '@react-spring/web'
import {
  Modal,
} from '@selectra-it/selectra-ui'
import {
  useState,
} from 'react'
import {
  Route, Routes,
  useParams,
} from 'react-router-dom'
import {
  useDispatch,
} from 'react-redux'

import useGetUserFromEstimationIdRouteParam from '@hooks/offers/useGetUserFromEstimationIdRouteParam'
import {
  LeadsourceOption, Owner,
} from '@root/domain/crm/Record'
import useGetOffersByEstimationId from '@hooks/offers/useGetOffersByEstimationId'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  HELP_MODAL, isModalOpen,
  toggleModal,
} from '@root/services/modalSlice'

import {
  HELP_CALLBACK_REQUEST, HELP_INFORMATION,
} from './HelpModal/routes'
import HelpInformation from './HelpModal/HelpInformation'
import HelpRequestCallback from './HelpModal/HelpRequestCallback'

const HelpModal = () => {
  const [location, setLocation] = useState<string>(HELP_INFORMATION)
  const isOpen = useAppSelector(state => isModalOpen(state, HELP_MODAL))
  const dispatch = useDispatch()

  const transitions = useTransition(location, {
    from: {
      transform: 'translateX(10%)',
      opacity: 0,
    },
    enter: {
      transform: 'translateX(0%)',
      opacity: 1,
    },
  })

  /**
   * Reset modal location when modal is closed
   */
  const onAfterClose = () => {
    setLocation(HELP_INFORMATION)
  }

  const setIsOpen = () => {
    dispatch(toggleModal(HELP_MODAL))
  }

  const {
    fetchUser: {
      data,
    },
  } = useGetUserFromEstimationIdRouteParam()
  const params = useParams()
  const estimationId = params.estimationId ?? ''

  const {
    data: estimationData,
  } = useGetOffersByEstimationId(estimationId)

  const mainRecipient = estimationData?.estimation.adults.find(adult => adult.type === 'PRINCIPAL')

  return (
    <Modal open={isOpen} setOpen={setIsOpen}
      onAfterClose={onAfterClose}
    >
      {transitions((styles, location) => (
        <animated.div style={styles} className='flex grow flex-col gap-4  md:gap-6 md:p-2'>
          <Routes location={location}>
            <Route path={HELP_INFORMATION} element={<HelpInformation setLocation={setLocation} />} />
            <Route path={HELP_CALLBACK_REQUEST} element={
              <HelpRequestCallback user={data}
                owner={Owner.INSURANCE_FRANCE_MANAGER} leadSourceOption={LeadsourceOption.COMPARATOR_HELP}
                address={estimationData?.estimation.address}
                mainRecipient={mainRecipient}
              />}
            />
          </Routes>
        </animated.div>
      ))}
    </Modal>
  )
}

export default HelpModal
