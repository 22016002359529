import {
  Button,
  ButtonGroup,
  ButtonGroupVariant,
  ButtonSize,
  ButtonType,
  Caption,
  Modal,
  TextVariant,
} from '@selectra-it/selectra-ui'
import {
  useEffect,
} from 'react'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import {
  useDispatch,
} from 'react-redux'
import {
  useParams,
} from 'react-router-dom'
import {
  useTranslation,
} from 'react-i18next'

import useGetButtonGroupStatus from '@hooks/useGetButtonGroupStatus'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectOfferFilters,
  setFilters,
} from '@root/services/filterSlice'
import {
  useLazyGetOffersByEstimationIdQuery,
} from '@root/services/offers'
import {
  isModalOpen, OFFER_FILTER_MODAL, toggleModal,
} from '@root/services/modalSlice'
import {
  Coverage, CoverageOptions as options,
} from '@root/domain/Offer'
import Icon from '@components/Icon'

import CoverageLevelOption from './CoverageLevelModal/CoverageLevelOption'

interface CoverageLevelFilterForm {
  regularHealthLevel: Coverage,
  opticalLevel: Coverage,
  hospitalLevel: Coverage,
  dentalLevel: Coverage,
  alternativeMedicine: string,
}

const CoverageLevelFilterModal = () => {
  const {
    estimationId,
  } = useParams<{estimationId: string}>()
  const isOpen = useAppSelector(state => isModalOpen(state, OFFER_FILTER_MODAL))
  const dispatch = useDispatch()
  const filters = useAppSelector(selectOfferFilters)
  const [trigger, {
    data,
  }] = useLazyGetOffersByEstimationIdQuery()
  const {
    t,
  } = useTranslation(['offers'])

  const {
    formState: {
      errors,
    },
    control,
    watch,
  } = useForm<CoverageLevelFilterForm>({
    defaultValues: {
      dentalLevel: filters.dentalLevel,
      hospitalLevel: filters.hospitalLevel,
      opticalLevel: filters.opticalLevel,
      regularHealthLevel: filters.regularHealthLevel,
      alternativeMedicine: filters.alternativeMedicine,
    },
  })

  const setIsOpen = () => {
    dispatch(toggleModal(OFFER_FILTER_MODAL))
  }

  const regularHealthLevelStatus = useGetButtonGroupStatus(errors.regularHealthLevel?.type)
  const opticalLevelStatus = useGetButtonGroupStatus(errors.opticalLevel?.type)
  const hospitalLevelStatus = useGetButtonGroupStatus(errors.hospitalLevel?.type)
  const dentalLevelStatus = useGetButtonGroupStatus(errors.dentalLevel?.type)
  const alternativeMedicineStatus = useGetButtonGroupStatus(errors.alternativeMedicine?.type)

  const alternativeMedicine = watch('alternativeMedicine')
  const opticalLevel = watch('opticalLevel')
  const hospitalLevel = watch('hospitalLevel')
  const dentalLevel = watch('dentalLevel')
  const regularHealthLevel = watch('regularHealthLevel')

  useEffect(() => {
    trigger({
      estimationId: estimationId as string,
      regularHealthLevel,
      opticalLevel,
      hospitalLevel,
      dentalLevel,
      alternativeMedicine: Boolean(alternativeMedicine),
    })
  }, [alternativeMedicine, opticalLevel, hospitalLevel, dentalLevel, regularHealthLevel, trigger, estimationId])

  if (!options) {
    return null
  }

  const coverageLevelOptions = options.map(option => ({
    icon: <CoverageLevelOption level={option} />,
    value: option.value,
  }))

  return (
    <Modal open={isOpen} setOpen={setIsOpen}
      spacing='default'
    >
      <div className='flex h-full flex-col gap-6'>
        <div className='flex flex-col items-center gap-4 overflow-y-auto'>
          <div className='flex flex-wrap content-center items-center gap-2 self-stretch fill-[#808284]'>
            <Icon name='edit' size={24} />
            <h3 className='text-[17px] font-semibold leading-[128%] tracking-[-0.221px] text-[#343535]'>Modifier mes besoins</h3>
          </div>

          <div className='flex flex-col items-start gap-2 self-stretch'>
            <div className='flex items-center gap-[6px]'>
              <Icon name='stethoscope' size={20}
                className='fill-[#808284]'
              />
              <h4 className='text-base font-semibold leading-[25.6px] tracking-[-0.176px] text-neutral-400'>Soins courants</h4>
            </div>

            <div className='mt-2 w-full'>
              <Controller
                control={control}
                name='regularHealthLevel'
                render={({
                  field: {
                    value,
                    ref,
                    name,
                    onChange,
                    onBlur,
                  },
                }) => (
                  <ButtonGroup options={coverageLevelOptions}
                    status={regularHealthLevelStatus} variant={ButtonGroupVariant.LIGHT}
                    currentValue={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>

            <Caption variant={TextVariant.REGULAR}>{t(`offers:coverageLevelModal.regularHealth.${regularHealthLevel}`)}</Caption>
          </div>

          <div className='flex flex-col items-start gap-2 self-stretch'>
            <div className='flex items-center gap-[6px]'>
              <Icon name='home-health' size={20}
                className='fill-[#808284]'
              />
              <h4 className='text-base font-semibold leading-[25.6px] tracking-[-0.176px] text-neutral-400'>Hospitalisation</h4>
            </div>

            <div className='mt-2 w-full'>
              <Controller
                control={control}
                name='hospitalLevel'
                render={({
                  field: {
                    value,
                    ref,
                    name,
                    onChange,
                    onBlur,
                  },
                }) => (
                  <ButtonGroup options={coverageLevelOptions}
                    status={hospitalLevelStatus} variant={ButtonGroupVariant.LIGHT}
                    currentValue={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>

            <Caption variant={TextVariant.REGULAR}>{t(`offers:coverageLevelModal.hospital.${hospitalLevel}`)}</Caption>
          </div>

          <div className='flex flex-col items-start gap-2 self-stretch'>
            <div className='flex items-center gap-[6px]'>
              <Icon name='visibility-on' size={20}
                className='fill-[#808284]'
              />
              <h4 className='text-base font-semibold leading-[25.6px] tracking-[-0.176px] text-neutral-400'>Optique</h4>
            </div>

            <div className='mt-2 w-full'>
              <Controller
                control={control}
                name='opticalLevel'
                render={({
                  field: {
                    value,
                    ref,
                    name,
                    onChange,
                    onBlur,
                  },
                }) => (
                  <ButtonGroup options={coverageLevelOptions}
                    status={opticalLevelStatus} variant={ButtonGroupVariant.LIGHT}
                    currentValue={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>

            <Caption variant={TextVariant.REGULAR}>{t(`offers:coverageLevelModal.optical.${opticalLevel}`)}</Caption>
          </div>

          <div className='flex flex-col items-start gap-2 self-stretch'>
            <div className='flex items-center gap-[6px]'>
              <Icon name='dentistry' size={20}
                className='fill-[#808284]'
              />
              <h4 className='text-base font-semibold leading-[25.6px] tracking-[-0.176px] text-neutral-400'>Dentaires</h4>
            </div>

            <div className='mt-2 w-full'>
              <Controller
                control={control}
                name='dentalLevel'
                render={({
                  field: {
                    value,
                    ref,
                    name,
                    onChange,
                    onBlur,
                  },
                }) => (
                  <ButtonGroup options={coverageLevelOptions}
                    status={dentalLevelStatus} variant={ButtonGroupVariant.LIGHT}
                    currentValue={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>

            <Caption variant={TextVariant.REGULAR}>{t(`offers:coverageLevelModal.dental.${dentalLevel}`)}</Caption>
          </div>

          <div className='flex flex-col items-start gap-2 self-stretch'>
            <div className='flex items-center gap-[6px]'>
              <h4 className='text-base font-semibold leading-[25.6px] tracking-[-0.176px] text-neutral-400'>Médecine douce</h4>
            </div>

            <div className='mt-2 w-full'>
              <Controller
                control={control}
                name='alternativeMedicine'
                render={({
                  field: {
                    value,
                    ref,
                    name,
                    onChange,
                    onBlur,
                  },
                }) => (
                  <ButtonGroup
                    options={[
                      {
                        label: 'Oui',
                        value: 'true',
                      },
                      {
                        label: 'Pas nécessaire',
                        value: '',
                      }
                    ]}
                    status={alternativeMedicineStatus} variant={ButtonGroupVariant.LIGHT}
                    currentValue={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>
            <Caption variant={TextVariant.REGULAR}>{t(`offers:coverageLevelModal.alternativeMedicine.${alternativeMedicine}` as 'offers:coverageLevelModal.alternativeMedicine.true')}</Caption>
          </div>

        </div>
        <Button variant={ButtonType.PRIMARY} size={ButtonSize.MEDIUM}
          onClick={() => {
            dispatch(
              setFilters({
                regularHealthLevel,
                opticalLevel,
                hospitalLevel,
                dentalLevel,
                alternativeMedicine,
              })
            )
            setIsOpen()
          }}
          pill
          label={`Voir les ${data?.items?.length ?? 0} offres`}
        />
      </div>
    </Modal>
  )
}

export default CoverageLevelFilterModal
