import {
  createSlice, PayloadAction,
} from '@reduxjs/toolkit'

import {
  Coverage,
} from '@root/domain/Offer'
import {
  RootState,
} from '@root/store/Reducer'

export interface OfferFiltersState {
  regularHealthLevel?: Coverage
  opticalLevel?: Coverage
  hospitalLevel?: Coverage
  dentalLevel?: Coverage
  alternativeMedicine: string
}

const initialState: OfferFiltersState = {
  regularHealthLevel: undefined,
  opticalLevel: undefined,
  hospitalLevel: undefined,
  dentalLevel: undefined,
  alternativeMedicine: '',
}

const offerFiltersSlice = createSlice({
  name: 'offerFilters',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Partial<OfferFiltersState>>) => {
      return {
        ...state, ...action.payload,
      }
    },
  },
})

export const selectOfferFilters = (state: RootState) => state.filter

export const {
  setFilters,
} = offerFiltersSlice.actions
export default offerFiltersSlice.reducer
