import {
  FC,
} from 'react'
import {
  Outlet,
} from 'react-router-dom'
import {
  Default,
  useScrollToTop,
} from '@selectra-it/selectra-ui'

import Header from '@components/ui/Header'
import useIsOfferPage from '@hooks/useIsOfferPage'
import ProgressBar from '@components/wizard/ProgressBar'
// import Debugger from '@components/ui/Debugger'

const App: FC = () => {
  useScrollToTop('offre')

  const {
    isOfferPage,
  } = useIsOfferPage()

  return (
    <Default header={(
      <>
        <Header />
        {!isOfferPage && <ProgressBar />}
      </>
    )}
    >
      <Outlet />

      {/* <Debugger /> */}
    </Default>
  )
}

export default App
