import {
  FC,
} from 'react'
import {
  Link,
} from 'react-router-dom'

const NotFound: FC = () => {
  return (
    <div className='flex flex-col'>
      <h2 className='mb-4 text-2xl'>La page n&apos;a pas été trouvée</h2>
      <Link className='underline' to="/">Retour à l&apos;accueil</Link>
    </div>
  )
}

export default NotFound
