import {
  FC,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'

import {
  Coverage,
  CoverageItem,
} from '@root/domain/Offer'

import CoverageLevelVerticalBars from '../CoverageLevel/CoverageLevelVerticalBars'

interface CoverageLevelOptionProps {
  level: CoverageItem
}

const CoverageLevelOption: FC<CoverageLevelOptionProps> = ({
  level,
}) => {
  const {
    t,
  } = useTranslation(['wizard'])

  return (
    <div className='flex flex-col items-center justify-center gap-1'>
      <CoverageLevelVerticalBars level={level.id as Coverage} />
      <span className=''>{t(`wizard.steps.coverageLevel.${level.id}.label` as 'wizard.steps.coverageLevel.HIGH.label')}</span>
    </div>
  )
}

export default CoverageLevelOption
