import {
  FC,
} from 'react'
import {
  Link,
  useParams,
} from 'react-router-dom'
import {
  Button,
  ButtonSize,
  ButtonType,
  InteractionMessageAgent,
  Subheading,
  SubheadingType,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'

import useGetUserFromEstimationIdRouteParam from '@hooks/offers/useGetUserFromEstimationIdRouteParam'
import Icon from '@components/Icon'
import {
  readablePhoneNumber,
} from '@root/util/formatting'
import FrenchFlag from '@assets/icons/french-flag.svg?react'
import {
  LeadsourceOption, PhoneNumbers,
} from '@root/domain/crm/Record'

const RequestCallbackSelectraSingle: FC = () => {
  const {
    offerId, estimationId,
  } = useParams()

  const {
    fetchUser: {
      data: user,
    },
  } = useGetUserFromEstimationIdRouteParam()

  const {
    t,
  } = useTranslation(['offers'])

  const phoneNumber = PhoneNumbers[LeadsourceOption.COMPARATOR_HELP_CARD]

  if (!user) {
    return null
  }

  return (
    <>
      <Subheading type={SubheadingType.TERTIARY}>
        {t('offers:mer.request_callback_selectra.title')}
      </Subheading>

      <div className='flex flex-col gap-6'>
        <div className='flex flex-col items-center gap-4 rounded-lg border border-neutral-150 p-[16px] '>
          <a href="tel:+33 1 86 65 26 10" className='flex items-center gap-4 text-2xl font-semibold text-primary-400'>
            <InteractionMessageAgent />{readablePhoneNumber(phoneNumber)}<FrenchFlag />
          </a>
          <p className='text-center text-xs text-neutral-400'>
            Ouvert aujourd’hui de 7h à 21h.
          </p>
        </div>
        <div className='flex items-center gap-1'>
          <div className='h-[2px] grow border border-neutral-100' />
          <span className='text-base font-semibold text-[#939597]'>OU</span>
          <div className='h-[2px] grow  border border-neutral-100' />
        </div>
        <Link className='w-full' to={`/offre/${estimationId}/${offerId}/mer/callback-selectra/`}>
          <Button variant={ButtonType.PRIMARY}
            pill
            size={ButtonSize.MEDIUM}
            label='Rappel gratuit de Selectra'
            iconLeft={() => (
              <Icon width={20} height={20}
                name='phone'
              />
            )}
          />
        </Link>
      </div>
    </>
  )
}

export default RequestCallbackSelectraSingle
