import {
  combineReducers,
} from '@reduxjs/toolkit'
import {
  TypedUseSelectorHook, useSelector,
} from 'react-redux'

import {
  apiService,
} from '@root/services/apiService'
import modalSliceReducer from '@root/services/modalSlice'
import formSliceReducer from '@root/services/formSlice'
import filterSliceReducer from '@root/services/filterSlice'

export const rootReducer = combineReducers({
  [apiService.reducerPath]: apiService.reducer,
  modal: modalSliceReducer,
  form: formSliceReducer,
  filter: filterSliceReducer,
})

export type RootState = ReturnType<typeof rootReducer>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
